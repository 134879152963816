<template>
  <el-row :gutter="20">
    <el-col :span="1">
      <div class="grid-content bg-purple"></div>
    </el-col>
    <el-col :span="7">
      <h1>{{ headT }}</h1>
    </el-col>
    <el-col :span="10">
      <div class="grid-content bg-purple"></div>
    </el-col>
    <el-col :span="5">
      <div class="grid-content bg-purple">
        <div style="margin-top: 35px">
          <el-input placeholder="search items" v-model="searchItem" class="input-with-select"
            @keyup.enter.native="search" size="large">
            <template #append>
              <el-button icon="el-icon-search" @click="search"></el-button>
            </template>
          </el-input>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
  export default {
    name: 'MetaboliteBrowseCompnentSearch',
    data () {
      return {
        searchItem: '',
        selectItem: "metabolite",
      }
    },
    methods: {
      search () {
        var searchable = true
        if (this.searchItem.search(/C\d*H\d*/i) === 0) {
          this.selectItem = 'molecular'
        } else if (this.searchItem.search(/^InChI=/) === 0) {
          this.selectItem = 'inchi'
        } else if (this.searchItem.search(/^MS\d{9}/i) === 0) {
          this.selectItem = 'metabolite'
        } else if (!isNaN(this.searchItem)) {
          this.selectItem = 'weight'
          var searchItemT = Number(this.searchItem)
          if (searchItemT == 0) {
            searchable = false
          } else {
            this.searchItem = Number(this.searchItem)
          }
        } else {
          this.selectItem = 'smiles'
        }
        if (searchable) {
          this.$router.push({ name: 'MetaboliteSearch', params: { selectItem: this.selectItem, searchItem: this.searchItem } })
        }
      }
    },
    props: {
      headT: {
        type: String,
        default: 'Bad connect!'
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>