<template>
  <div id="networkdetailcompnent">
    <el-row>
      <el-col :span="1">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="14">
        <div id="mynetwork" style="border: 1px solid black"></div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="1">
        <div class="grid-content bg-purple"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import request from '@/network/request'
  import $ from 'jquery'
  import vis from "vis-network/dist/vis-network.min.js"
  import "vis-network/dist/dist/vis-network.min.css"
  import ChemDoodle from "../../mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  // const vis = require("vis-network/dist/vis-network.min.js")
  let canvas
  let context1
  export default {
    name: 'NetworkDetailCompnent',
    data () {
      return {
        networkkeggDict: {},
        selectedMetaboliteInfo: "",
      }
    },
    methods: {
      search () {
      },
      router2metaboliteDetail (params) {
        var selectedMetabolite = params.nodes[0]
        var searchItemT = ""
        if (selectedMetabolite.includes("C")) {
          if (selectedMetabolite in this.networkkeggDict) {
            searchItemT = this.networkkeggDict[selectedMetabolite]
          }
        } else {
          searchItemT = selectedMetabolite
        }
        if (searchItemT != "") {
          this.$router.push({ name: 'MetaboliteDetail', params: { format: 'json', searchItem: searchItemT } })
        }
      },
      plotNetwork (edgedata, networkkegg, networkmetabolite) {
        var colorPlate = ["#8dd3c7", "#bebada", "#fb8072", "#80b1d3", "#fdb462", "#b3de69", "#fccde5", "#d9d9d9", "#bc80bd", "#ccebc5", "#ffed6f", "#ffffb3"]
        var node = new Set()
        var nodeKeggCompound = []
        var edgeList = []
        this.networkkeggDict = {}
        for (let i = 0; i < networkkegg.length; i++) {
          this.networkkeggDict[networkkegg[i].keggId] = networkkegg[i].metabolite
          nodeKeggCompound.push(networkkegg[i].metabolite)
        }
        for (let i = 0; i < edgedata.length; i++) {
          node.add(edgedata[i].nodefrom)
          node.add(edgedata[i].nodeto)
          edgeList.push({ "from": edgedata[i].nodefrom, "to": edgedata[i].nodeto, "label": edgedata[i].reaction, "group": "Kegg2kegg", "color": colorPlate[0] })
        }
        var node2 = [...node]
        var nodeList = []
        for (let i = 0; i < node2.length; i++) {
          if (node2[i] in this.networkkeggDict) {
            nodeList.push({
              "id": node2[i],
              "label": node2[i],
              "group": "kegg compound",
              "metabolite": this.networkkeggDict[node2[i]],
              color: {
                border: '#fdb462',
                background: '#fdb462',
                highlight: {
                  border: '#ffed6f',
                  background: '#ffed6f'
                },
              },
            })
          } else {
            nodeList.push({
              "id": node2[i],
              "label": node2[i],
              "group": "kegg compound",
              "metabolite": "",
              color: {
                border: 'grey',
                background: 'grey',
                highlight: {
                  border: '#ffed6f',
                  background: '#ffed6f'
                },
              },
            })
          }
        }
        for (let i = 0; i < networkmetabolite.length; i++) {
          if (!(networkmetabolite[i].metabolite in nodeKeggCompound)) {
            nodeList.push({
              "id": networkmetabolite[i].metabolite,
              "label": networkmetabolite[i].metabolite,
              "group": "other compound",
              "metabolite": networkmetabolite[i].metabolite,
              color: {
                border: '#ccebc5',
                background: '#ccebc5',
                highlight: {
                  border: '#ffed6f',
                  background: '#ffed6f'
                },
              },
            })
            edgeList.push({
              "from": networkmetabolite[i].similarKegg,
              "to": networkmetabolite[i].metabolite,
              "group": "Metabolite2kegg",
              "color": "grey"
            })
          }
        }
        var options = {
          nodes: {
            borderWidth: 1,
            font: {
              size: 10
            },
          },
          edges: {
            // color: 'lightgray',
            // color: "#8dd3c7",
            arrows: {
              to: {
                enabled: true,
                type: "arrow",
              },
            },
            width: 3
          },
          // physics: true,
          physics: {
            stabilization: false,
            barnesHut: {
              gravitationalConstant: -1000,
              springConstant: 0.01,
              springLength: 50,
              avoidOverlap: 0.0,
              centralGravity: 0.5,
            },
          },
          // physics: {
          //   stabilization: false,
          //   barnesHut: {
          //     gravitationalConstant: -80000,
          //     springConstant: 0.001,
          //     springLength: 200,
          //   },
          // },
          layout: {
            // hierarchical: {
            //   enabled: true,
            // },
            randomSeed: 2,
            improvedLayout: false,
          },
          interaction: {
            tooltipDelay: 200,
            hideEdgesOnDrag: true,
            // hideEdgesOnZoom: true,
          },
        }

        var container = document.getElementById("mynetwork");
        var data = {
          nodes: nodeList,
          edges: edgeList,
        };
        let network = new vis.Network(container, data, options)
        network.on("doubleClick", this.router2metaboliteDetail)
      }
    },
    props: {
      networkData: {
        type: Object,
        default: {},
      },
      head: {
        type: String,
        default: 'Requesting !',
      },
    },
    components: {

    },
    created () {
    },
    mounted () {
    },
    updated () {
    },
    watch: {
      networkData: {
        handler (val, oldVal) {
          this.plotNetwork(val.Network, val.networkkegg, val.networkmetabolite)
        }
      }
    }

  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  #mynetwork {
    width: 100%;
    height: 800px
  }
</style>